import React from "react"
import Container from "../../utils/container"
import Flex from "../../utils/flex"
import ButtonGroup from "../../utils/button/group"

import ctl from "@netlify/classnames-template-literals"

const LearnAtYourOwnPage = () => {
  return (
    <Container>
      <Flex
        firstItem={<></>}
        secondItem={
          <>
            {" "}
            <h2>
              Learn at your own <br />
              pace. Go the entire 9 <br />
              yards with Harry
            </h2>
            <p className={paragraphStyle}>
              Find out what financial instruments are,
              <br />
              understand the lingo of the pros, Use technical tools,
              <br />
              beat the markets and make profits.
            </p>
            <ButtonGroup buttons={buttons} />
          </>
        }
      />
    </Container>
  )
}

const paragraphStyle = ctl(`text-[14px] `)

const buttons = [
  {
    label: `Start Learning`,
    url: `/academy/guides`,
  },
  {
    label: `Try Demo Trading`,
    url: `https://mt4.egmarkets.trade/`,
    isExternal: true
  },
]
export default LearnAtYourOwnPage
