import * as React from "react"
import Layout from "../components/layout"
import BecomeSmarter from "../components/pages/learn-from-masters/become-smarter"
import LearnAtYourOwnPage from "../components/pages/learn-from-masters/learn-at-your-own-pace"

import {
  ReceiveTradingSupport,
  TradingSteps,
  TradingTargets,
} from "../components/utils/trading"

const LearnFromMastersPage = () => (
  <Layout title="Learn from master traders">
    <BecomeSmarter />
    <LearnAtYourOwnPage />
    <TradingTargets />
    <ReceiveTradingSupport />
    <TradingSteps />
  </Layout>
)

export default LearnFromMastersPage
