import React from "react"
import Container from "../../utils/container"
import Flex from "../../utils/flex"

import ctl from "@netlify/classnames-template-literals"

const BecomeSmarter = () => {
  return (
    <Container>
      <Flex
        firstItem={
          <>
            <h2>
              Become a smarter <br />
              trader with these 8 <br />
              proven steps
            </h2>
            <p className={paragraphStyle}>
              Find out what financial instruments are,
              <br />
              understand the lingo of the pros, Use technical tools,
              <br />
              beat the markets and make profits.
            </p>
          </>
        }
        secondItem={<></>}
      />
    </Container>
  )
}

const paragraphStyle = ctl(`text-[14px] `)
export default BecomeSmarter
